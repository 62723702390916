<!-- Dialog Image Bacground -->
<div mat-dialog-title>
    <!-- Close Button -->
    <div class="position-button">
        <button class="close" mat-dialog-close mat-button><img src="assets/images/x.png" /></button>
    </div>
    <!-- Logo Image -->
    <div class="position-logoImage">
        <img class="logo-synergy" src="assets/images/synergyLogo2.png" />
    </div>
</div>
<!-- Dialog Content -->
<div mat-dialog-content>
    <div class="forms-position" [formGroup]="loginForm">
        <!-- User Name Input -->
        <mat-form-field class="div-pos" appearance="standard">
            <!-- Placholder -->
            <mat-label class="placholder-color">Email</mat-label>
            <!-- Input Leabel -->
            <input (keyup.enter)="onEnter()" class="input-size" type="text" matInput formControlName="userName">
        </mat-form-field>
        <!-- Password Input -->
        <mat-form-field class="div-pos" appearance="standard">
            <!-- Placholder -->
            <mat-label class="placholder-color">{{'login_password' | translate}}</mat-label>
            <!-- Input Leabel -->
            <input (keyup.enter)="onEnter()" class="input-size" type="password" matInput
                [type]="hideLogin ? 'password' : 'text'" matInput formControlName="password">
            <!-- Eye -->
            <span class="login-eye" mat-icon-button matSuffix (click)="hideLogin = !hideLogin"
                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideLogin">
                <mat-icon>{{hideLogin ? 'visibility_off' : 'visibility'}}</mat-icon>
            </span>
        </mat-form-field>
        <!-- Remember Me Check Box -->
        <div class="check-box-width">
            <mat-checkbox class="pe-2" formControlName="rememberMe">{{'login_check_box' | translate}}</mat-checkbox>
        </div>
        <!-- Forgot Password -->
        <div class="text-center mt-5">
            <p class="forgot-pass" (click)="openForgotDialog()">{{'login_forgot_password' | translate}}</p>
        </div>
        <!-- Actions -->
        <div class="mt-5">
            <!-- Loader -->
            <div class="loader-position" *ngIf="displayLoader">
                <mat-spinner class="loader" [diameter]="70"></mat-spinner>
            </div>
            <!-- Error Massage -->
            <div>
                <p class="error-massage">{{loginErrorMassage | translate}}</p>
            </div>
            <!-- Submit -->
            <button class="submit" [disabled]="disableButton" (click)="leabelsCheck()">{{'login_submit' |
                translate}}</button>
        </div>
    </div>
</div>
<!-- Dialog Actions -->
<div mat-dialog-actions></div>