<footer id="footerId" class="theme-bg  text-white">
    <div class="row p-3">
        <!-- Company Details PDF -->
        <div class="col-sm-3 alignment-padding">
            <a class="company-details">{{'footer_company_info' | translate}}</a>
            <!-- Financial Statements 2020 - 2021 - 2022 PDFS -->
            <div class="d-flex mt-1">
                <a class="company-years" href="assets/pdf/Χρηματοοικονομικές-Καταστάσεις-2020.pdf"
                    target="_blank">2020</a>
                <div class="space-div"></div>
                <a class="company-years" href="assets/pdf/Χρηματοοικονομικές-Καταστάσεις-2021.pdf"
                    target="_blank">2021</a>
                <div class="space-div"></div>
                <a class="company-years" href="assets/pdf/Χρηματοοικονομικές-Καταστάσεις-2022.pdf"
                    target="_blank">2022</a>
                <div class="space-div"></div>
                <a class="company-years" href="assets/pdf/Χρηματοοικονομικές-Καταστάσεις-2023.pdf"
                    target="_blank">2023</a>
            </div>
        </div>
        <!-- Services And Number -->
        <div class="col-sm-3 alignment-padding">
            <p class="service-number-word">{{'footer_service_number' | translate}}</p>
            <p class="service-number-numb">18198</p>
        </div>
        <div class="col-sm-6 text-end alignment-padding">
            <!-- Copyright -->
            <h4>Copyright © 2021 ElpeFuture</h4>
            <div>
                <!-- Privacy Policy PDF -->
                <a class="terms-more" href="{{'footer_html_privacy_policy' | translate}}"
                    target="_blank">{{'footer_privacy' |
                    translate}} | </a>
                <!-- Terms Of Use PDF -->
                <a class="terms-more" href="{{'footer_pdf_terms' | translate}}" target="_blank"> {{'footer_terms' |
                    translate}}
                </a>
            </div>
        </div>
    </div>
</footer>