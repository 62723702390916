<div class="container">
  
  <!-- sticky nav -->
  <nav class="sticky-nav" [ngClass]="{ 'no-bullet': isManualNavigation }" aria-label="Charging Methods Navigation">
    <ul>
      <li>
        <a [routerLink]="[]" [fragment]="'rfid-card'" (click)="onNavLinkClick('rfid-card')"
          [ngClass]="{ 'active': currentSection === 'rfid-card' }"
          [attr.aria-current]="currentSection === 'rfid-card' ? 'page' : null">
          {{'how_to_charge.sticky_nav_rfid' | translate}}
        </a>
      </li>
      <li>
        <a [routerLink]="[]" [fragment]="'charge-go-app'" (click)="onNavLinkClick('charge-go-app')"
          [ngClass]="{ 'active': currentSection === 'charge-go-app' }"
          [attr.aria-current]="currentSection === 'charge-go-app' ? 'page' : null">
          {{'how_to_charge.sticky_nav_app' | translate}}
        </a>
      </li>
      <li>
        <a [routerLink]="[]" [fragment]="'credit-card'" (click)="onNavLinkClick('credit-card')"
          [ngClass]="{ 'active': currentSection === 'credit-card' }"
          [attr.aria-current]="currentSection === 'credit-card' ? 'page' : null">
          {{'how_to_charge.sticky_nav_pos' | translate}}
        </a>
      </li>
      <li>
        <a [routerLink]="[]" [fragment]="'gas-station'" (click)="onNavLinkClick('gas-station')"
          [ngClass]="{ 'active': currentSection === 'gas-station' }"
          [attr.aria-current]="currentSection === 'gas-station' ? 'page' : null">
          {{'how_to_charge.sticky_nav_gas_station' | translate}}
        </a>
      </li>
    </ul>
  </nav>
  <div class = "logo">
    <img src="assets/images/synergyLogo2.png">
  </div>
  <!-- main content -->
  <div class="content">
    
        <!-- RFID card -->
        <section id="rfid-card" class="method-section">
          <div class="main-title" [ngClass]="{ 'expanded': expandedSections['rfid-card'] }"
            (click)="toggleExpand('rfid-card')" tabindex="0" (keydown.enter)="toggleExpand('rfid-card')"
            (keydown.space)="toggleExpand('rfid-card')" [attr.aria-expanded]="expandedSections['rfid-card']">
            <h2 [innerHTML]="'how_to_charge.rfid_header' | translate"></h2>
          </div>
          <div class="content-items" *ngIf="expandedSections['rfid-card']">
            <p [innerHTML]="'how_to_charge.rfid_intro' | translate"></p>
            <ol class="steps">
              <li [innerHTML]="'how_to_charge.rfid_start_steps.1' | translate"></li>
              <li [innerHTML]="'how_to_charge.rfid_start_steps.2' | translate"></li>
              <li [innerHTML]="'how_to_charge.rfid_start_steps.3' | translate"></li>
              <li [innerHTML]="'how_to_charge.rfid_start_steps.4' | translate"></li>
            </ol>
            
            <p class="note-text" [innerHTML]="'how_to_charge.rfid_note_vehicle_models' | translate"></p>
            <p [innerHTML]="'how_to_charge.rfid_stop_anytime_info' | translate"></p>
          </div>
        </section>

    <!-- charge go app -->
    <section id="charge-go-app" class="method-section">
      <div class="main-title" [ngClass]="{ 'expanded': expandedSections['charge-go-app'] }"
        (click)="toggleExpand('charge-go-app')" tabindex="0" (keydown.enter)="toggleExpand('charge-go-app')"
        (keydown.space)="toggleExpand('charge-go-app')" [attr.aria-expanded]="expandedSections['charge-go-app']">
        <h2>
          {{'how_to_charge.app_header' | translate}}
        </h2>
      </div>
      <div class="content-items" *ngIf="expandedSections['charge-go-app']">
        <p [innerHTML]="'how_to_charge.app_intro_1' | translate"></p>
        <ol class="steps">
          <li>
            {{'how_to_charge.app_start_steps.step_1.title' | translate}}
            <ul class="substeps">
              <li [innerHTML]="'how_to_charge.app_start_steps.step_1.substeps.1' | translate"></li>
              <li [innerHTML]="'how_to_charge.app_start_steps.step_1.substeps.2' | translate"></li>
            </ul>
          </li>
          <li [innerHTML]="'how_to_charge.app_start_steps.step_2' | translate"></li>
          <li [innerHTML]="'how_to_charge.app_start_steps.step_3' | translate"></li>
          <li [innerHTML]="'how_to_charge.app_start_steps.step_4' | translate"></li>
          <li [innerHTML]="'how_to_charge.app_start_steps.step_5' | translate"></li>
        </ol>
        <p [innerHTML]="'how_to_charge.app_start_failure_info' | translate"></p>
        <p class="note-text" [innerHTML]="'how_to_charge.app_note_vehicle_models' | translate"></p>
        <p [innerHTML]="'how_to_charge.app_stop_anytime_info' | translate"></p>
        <iframe class="video-frame" [src]="appVideo"></iframe>

      </div>
    </section>

    <!-- credit card  -->
    <section id="credit-card" class="method-section">
      <div class="main-title" [ngClass]="{ 'expanded': expandedSections['credit-card'] }"
        (click)="toggleExpand('credit-card')" tabindex="0" (keydown.enter)="toggleExpand('credit-card')"
        (keydown.space)="toggleExpand('credit-card')" [attr.aria-expanded]="expandedSections['credit-card']">
        <h2 [innerHTML]="'how_to_charge.credit_card_header' | translate"></h2>
      </div>
      <div class="content-items" *ngIf="expandedSections['credit-card']">
        <p [innerHTML]="'how_to_charge.credit_card_intro' | translate"></p>
        <ol class="steps">
          <li [innerHTML]="'how_to_charge.credit_card_start_steps.1' | translate"></li>
          <li [innerHTML]="'how_to_charge.credit_card_start_steps.2' | translate"></li>
          <li [innerHTML]="'how_to_charge.credit_card_start_steps.3' | translate"></li>
          <li [innerHTML]="'how_to_charge.credit_card_start_steps.4' | translate"></li>
          <li [innerHTML]="'how_to_charge.credit_card_start_steps.5' | translate"></li>
          <li [innerHTML]="'how_to_charge.credit_card_start_steps.6' | translate"></li>
          <li [innerHTML]="'how_to_charge.credit_card_start_steps.7' | translate"></li>
          <li [innerHTML]="'how_to_charge.credit_card_start_steps.8' | translate"></li>
        </ol>
        <p class="note-text" [innerHTML]="'how_to_charge.credit_card_note' | translate"></p>
        <p [innerHTML]="'how_to_charge.credit_card_outro' | translate"></p>
        <iframe class="video-frame" src="https://www.youtube.com/embed/0h3olmuy-Vc?si=RQ95l_m_FxW6rVKF"></iframe>
      </div>
    </section>

    <!--gas station -->
    <section id="gas-station" class="method-section">
      <div class="main-title" [ngClass]="{ 'expanded': expandedSections['gas-station'] }"
        (click)="toggleExpand('gas-station')" tabindex="0" (keydown.enter)="toggleExpand('gas-station')"
        (keydown.space)="toggleExpand('gas-station')" [attr.aria-expanded]="expandedSections['gas-station']">
        <h2 [innerHTML]="'how_to_charge.gas_station_header' | translate"></h2>
      </div>
      <div class="content-items" *ngIf="expandedSections['gas-station']">
        <p [innerHTML]="'how_to_charge.gas_station_intro_1' | translate"></p>
        <p [innerHTML]="'how_to_charge.gas_station_intro_2' | translate"></p>
        <div class="spacer"></div>
      </div>
    </section>

  </div>
</div>