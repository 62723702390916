import { Component, OnInit, HostListener } from '@angular/core';
import { Location } from "@angular/common";
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/userService/user.service';
import { DialogService } from 'src/app/services/dialogService/dialog.service';
import { DataService } from 'src/app/services/dataService/data.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.less']
})

export class NavbarComponent implements OnInit {
  scrollNavBar: boolean = true;
  locale!: string;
  currentRoute = "/";
  currentRouteSite = "/";
  dropMenu: any;
  loggedIn: any;
  inputCard: any;
  public screenWidth: any = window.innerWidth;
  cardEKO: any;
  /*
  Field that read configuration and enables banner-showing on navbar.
  This env variable also activates the bannerFeatureGuard.

  */
  public enabledBannerThroughConfiguration:boolean = environment.enableBannerFeature;
  
  constructor(
    public router: Router,
    public location: Location,
    private translate: TranslateService,
    private cookieService: CookieService,
    private userService: UserService,
    private dialogService: DialogService,
    private dataService: DataService) {
    //Event Run Then Change Route
    this.router.events.subscribe((ev) => {
      //Read The Current Route
      this.currentRouteSite = location.path();

      //Read The Acount Route
      if (ev instanceof NavigationEnd) {
        this.currentRoute = ev.url.split("/")[1];
        this.cardEKO = this.userService.getUser()?.SubscriptionType;
      }
      
      //Read The FirstTimeInputCard Property From Info Request Then Change Route
      this.inputCard = this.userService.getUser()?.FirstTimeInputCard;

      if (this.dataService.getParamButtons()) {
        this.inputCard = false;
      }

    });

    //Get LoggedIn Status If Client Is Logged In
    this.loggedIn = this.userService.isLoggedIn();

    //User Logged In Or Logout Listener
    this.userService.getLoggedInStatus.subscribe(resp => {
      //Get LoggedIn Status Client
      this.loggedIn = this.userService.isLoggedIn();

      //Read The Subscription Type Card Then LoggedIn And LoggedOut
      this.cardEKO = this.userService.getUser()?.SubscriptionType;
    });
  }

  ngOnInit(): void {
    //Read The Current Language
    this.locale = this.translate.currentLang;

    //Read The FirstTimeInputCard Property From Info Request 
    this.inputCard = this.userService.getUser()?.FirstTimeInputCard;

    //Read The Subscription Type Card
    this.cardEKO = this.userService.getUser()?.SubscriptionType;
    console.log("sub type : ", this.userService.getUser()?.SubscriptionType);
    console.log("sub types : ", this.userService);
    console.log("input card : ", this.userService.getUser()?.FirstTimeInputCard);
  }

  //Open Login Dialog
  openLoginDialog() {
    this.dialogService.openLoginDialogService();
  }

  //Open Register Dialog
  openRegisterDialog() {
    this.dialogService.openRegisterDialogService();
  }

  //Open Logout Dialog
  openLogoutDialog() {
    this.dialogService.openLogOutDialogService();
  }

  //Read Window Scroll
  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    if (window.pageYOffset <= 50) {
      this.scrollNavBar = true;
    } else {
      this.scrollNavBar = false;
    }
  }

  //Read Window Size (Width)
  @HostListener('window:resize', ['$event'])
  onResize(event?: undefined) {
    this.screenWidth = window.innerWidth;
  }

  //Change Language
  changeLanguage(): void {
    switch (this.translate.currentLang) {
      case 'en':
        this.translate.use('el');
        this.locale = 'el';
        this.cookieService.set('lang', 'el', { path: '/', domain: location.host.includes('localhost') ? 'localhost' : environment.domainCookieLang });
        break;
      case 'el':
        this.translate.use('en');
        this.locale = 'en';
        this.cookieService.set('lang', 'en', { path: '/', domain: location.host.includes('localhost') ? 'localhost' : environment.domainCookieLang });
        break;
      default:
        break;
    }
  }

}